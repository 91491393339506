import { PageProps } from 'gatsby';
import { DiscountData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import discount from '~/params/discount.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageDiscountCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => {
  const model = new DiscountData({
    espaceId,
    params: discount,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/discounts/`}
          model={model}
          name="discount"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageDiscountCreate);
